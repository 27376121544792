<template>
  <div class="vault-index">
    <v-container>
      <div class="small-rem-9 font-w-4 lightGrey1--text mt-sm-10 mb-sm-7 mx-sm-8">{{ $t('indexSubtitle') }}</div>
      <div class="rem-10 rem-sm-36 font-w-4 lightPrimary2--text mb-4 mb-sm-8 mx-sm-8 title-text" :style="{lineHeight: $store.state.nowWidth > 960 ? '60px' : '26px'}">
        {{ $t('indexTitle') }}
        <div class="font-w-7 rem-1 white home-lock-btn pr-5 pl-3 py-1 d-flex align-center" :style="{color: $store.state.isMember ? '#53A29C' : '#A70000'}">{{ $t($store.state.isMember ? 'unlock' : 'lock') }}</div>
      </div>

      <div class="index-card-wrap mb-14 mb-sm-10 px-sm-2 mr-sm-n3">
        <div class="index-card-scroll d-flex">
          <div
            class="index-card font-w-4 px-3 py-2 mr-2 border-radius-3 can-click"
            :class="currInterestToken === item.token ? 'bg-primary-radial-gradient darkGrey--text' : 'bg-grey-radial-gradient lightPrimary2--text'"
            v-for="(item, i) in $store.state.interestTokens"
            :key="i"
            @click="currInterestToken = item.token"
          >
            <img :src="`${require(`@/assets/img/icon-polygon-${item.token}-${currInterestToken === item.token ? 'grey' : 'primary'}.svg`)}`" :alt="item.token" class="mb-n1">
            <div class="rem-4 font-tauri">{{ $t(item.token).toUpperCase() }}</div>
            <div class="rem-0 font-tauri">Demand Deposits</div>
          </div>
        </div>
      </div>

      <div class="deposits-wrap px-sm-3">
        <div class="deposit-card d-flex justify-space-between align-center py-5 pr-3 pl-4 mb-3" v-for="(item, i) in $store.state.depositTokens" :key="i">
          <div class="d-flex align-center">
            <img class="mr-2" :src="`${require(`@/assets/img/icon-logo-${item.token}.svg`)}`" :alt="item.token">
            <div class="font-share-tech font-w-4">
              <span class="rem-13 rem-sm-16 lightPrimary2--text mr-1">{{ item.token.toUpperCase() }}</span>
              <span class="rem-3 rem-sm-4 lightGrey1--text">/{{ currInterestToken.toUpperCase() }}</span>
            </div>
          </div>
          <v-btn
            class="bg-primary-radial-gradient border-radius-3 darkGrey--text font-w-4 px-2"
            height="24"
            @click="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: currInterestToken, depositToken: item.token}})"
          >
            {{ $t('depositImmediately') }}
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data(){
    return {
      currInterestToken: '',
    }
  },
  methods: {
    
  },
  async mounted(){
    this.currInterestToken = this.$store.state.interestTokens[0].token
  }
}
</script>
<style lang="scss">
.vault-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    background: radial-gradient(188.38% 188.38% at 50% -88.38%, rgba(99, 99, 99, 0.8) 0%, rgba(71, 71, 71, 0.8) 40.12%, rgba(49, 49, 49, 0.8) 100%);
    border-radius: 11px;
    margin: 40px auto;
  }
  .index-card-wrap {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .index-card-scroll {
      width: fit-content; 
      .index-card {
        width: 142px;
      }
    }
  }

  .deposit-card {
    background: rgba(49, 49, 49, 0.8);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(3px);
    border-radius: 5px;
    @include dai_vuetify_sm_min {
      border: 1px solid #5D5D5D;
    }
  }

  .title-text {
    position: relative;
    .home-lock-btn {
      position: absolute;
      height: 24px;
      bottom: 12px;
      box-shadow: 0px 0.8px 0.8px rgba(0, 0, 0, 0.25);
      right: -44px;
      border-radius: 13px 0 0 13px;
    }
  }
}
</style>